<template>
  <view-wrapper :title="`询价单号: ${form.data.serialNo}`">
    <template #header>
      <div class="relative">
        <a-form>
          <a-row>
            <a-col :span="7">
              <a-form-item label="询价人">
                <span>{{ form.data.preparedBy }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="品质类型">
                <span v-for="item of form.data.inquiryQualityTypes" :key="item.code">{{ `${item.message} ` }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item label="询价时间">
                <span>{{ formatDate(form.data.preparedDatetime, true) }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="开票要求">
                <span>{{ form.data.invoiceType.message }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item label="VIN码">
                <span>{{ form.data.vin }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="备注">
                <div>{{ form.data.comment }}</div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item label="品牌/车型">
                <span>
                  {{ `${form.data.vehicleBrand}${form.data.vehicleBrand && '/'}${form.data.vehicleSubBrand}` }}
                </span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="附件">
                <div class="w-full flex items-center">
                  <FileImageOutlined
                    @click="imgPreviewModal.openClick"
                    style="width: 11px; height: 14px; color: #0d8d8d"
                  />
                  <span style="margin-left: 8px"
                    >({{ form.data.inquiryImages.length ? form.data.inquiryImages.length : 0 }}张)</span
                  >
                </div>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="w-[500px] h-[80px] text-right flex justify-between items-center absolute right-[24px] bottom-0">
          <div class="px-[10px] py-[10px] border-right-[1px]">
            <div class="text-[#7f7f7f]">报价完成率</div>
            <div>{{ finishingRate }}</div>
          </div>
          <div class="px-[10px] py-[10px] border-right-[1px]">
            <div class="text-[#7f7f7f]">需求数</div>
            <div>{{ form.data.inquiryGoods.length }}</div>
          </div>
          <div class="px-[10px] py-[10px] border-right-[1px]">
            <div class="text-[#7f7f7f]">报价数</div>
            <div>{{ biddingData }}</div>
          </div>
          <div class="px-[10px] py-[10px] border-right-[1px]">
            <div class="text-[#7f7f7f]">已下单数</div>
            <div>{{ form.data.purchaseCount }}</div>
          </div>
          <div style="padding: 10px">
            <div class="text-[#7f7f7f]">已接单数</div>
            <div>{{ form.data.purchasedCount }}</div>
          </div>
        </div>
        <div class="absolute right-[24px] top-0">
          <a-button class="mr-[9px]" @click="reInquiryClick">重新询价</a-button>
          <a-button type="primary" @click="reloadClick">刷新</a-button>
        </div>
      </div>
    </template>
    <div class="bg-[#fff] px-[24px] py-[24px]">
      <div v-if="!cartVisible" class="block_table-col-bgk">
        <div class="flex items-center justify-between mb-[32px]">
          <div>
            <span class="font-bold text-16px">展示规则：</span>
            <a-select v-model:value="filterRule" style="width: 200px" @change="tableGet">
              <a-select-option value="O" v-if="qualityTypeCodes.includes('O')">按原厂件最低价</a-select-option>
              <a-select-option value="B" v-if="qualityTypeCodes.includes('B')">按品牌件最低价</a-select-option>
              <a-select-option value="D" v-if="qualityTypeCodes.includes('D')">按拆车件最低价</a-select-option>
            </a-select>
          </div>

          <a-button @click="supplierDrawer.visible = true">供应商</a-button>
        </div>

        <a-table
          :data-source="tableData"
          :columns="tableColumns"
          :loading="formLoading"
          row-key="id"
          :pagination="false"
          :scroll="{ x: 1380 }"
        >
          <template #checkboxAll="{}">
            <a-checkbox :checked="checkAll" @change="checkAllChange" />
          </template>
          <template #checkbox="{ record }">
            <a-checkbox
              v-model:checked="record.checked"
              :disabled="Boolean(!record.quotationCount || !record.goods.qualityType.code)"
              @change="checkChange(record)"
            />
          </template>
          <template #count="{ record }">
            <div :class="[{ 'switch-block': record.quotationCount && record.checked }, 'h-[30px]']">
              <span class="switch-block__text">{{ record.count }}</span>
              <a-tag
                v-if="record.purchased && record.purchased.value"
                class="switch-block__text !bg-[#FFFBE6] !border-[#ffd61c] border-[1px] !text-[#ffd61c] !ml-[13px]"
                >已下单</a-tag
              >
              <e-input-number v-model="record.count" class="switch-block__input" :min="1"></e-input-number>
            </div>
          </template>
          <template #price="{ record, text }">
            <span class="font-bold text-[16px]" v-if="record.quotationCount && record.goods.qualityType.code">{{
              formatMoney(text, '￥')
            }}</span>
            <span v-else>-</span>
          </template>
          <template #qualityType="{ record, text }">
            <div class="flex items-center" v-if="record.quotationCount && record.goods.qualityType.code">
              <img
                v-if="record.goods.qualityType.code === 'O'"
                class="w-14px h-14px mr-8px"
                src="@client/assets/icon_origin.png"
                alt=""
              />
              <img
                v-if="record.goods.qualityType.code === 'B'"
                class="w-14px h-14px mr-8px"
                src="@client/assets/icon_quality.png"
                alt=""
              />
              <img
                v-if="record.goods.qualityType.code === 'D'"
                class="w-14px h-14px mr-8px"
                src="@client/assets/icon_car.png"
                alt=""
              />
              {{ text }}
            </div>
            <span v-else>-</span>
          </template>
          <template #warrantyMonth="{ record }">
            <span v-if="record.quotationCount && record.goods.qualityType.code"
              >{{ record.goods.warrantyMonth }}个月</span
            >
            <span v-else>-</span>
          </template>
          <template #reserveDays="{ record }">
            <span v-if="record.quotationCount && record.goods.qualityType.code">
              {{ record.goods.reserveDays ? record.goods.reserveDays + '天' : '现货' }}
            </span>
            <span v-else>-</span>
          </template>
          <template #offerer="{ record }">
            <div v-if="record.quotationCount && record.goods.qualityType.code">
              <span>{{ record.goods.preparedBy.name }}</span>
              <a-tooltip :title="record.goods.preparedBy.cellphone || '暂无'">
                <PhoneFilled class="ml-8px cursor-pointer" style="color: #0d8d8d; transform: rotateY(180deg)" />
              </a-tooltip>
              <MessageFilled
                v-if="record.goods.preparedBy.chatUser"
                @click="webim.open(record.goods.preparedBy.chatUser)"
                class="ml-8px cursor-pointer"
                style="color: #ffd100"
              />
            </div>
            <div v-else>-</div>
          </template>
          <template #operation="{ record }">
            <a-button
              v-if="record.quotationCount"
              class="operation-model"
              type="link"
              @click="tableOperationReplaceClick(record)"
              >替换</a-button
            >
          </template>
        </a-table>

        <div class="flex items-center justify-end mt-[24px]">
          <span v-if="!!summay.supplierCount">
            <span>选购 {{ summay.supplierCount }} 家供应商,</span>
            <span>&nbsp;共 {{ summay.size }} 种配件</span>
            <span style="margin-left: 24px">
              已选总额：
              <span class="font-bold text-21px">{{ formatMoney(summay.totalAmount, '￥', 2) }}</span>
            </span>
          </span>
          <a-button :disabled="!summay.supplierCount" class="ml-[24px]" type="primary" @click="purchaseClick"
            >一键采购</a-button
          >
        </div>
      </div>
      <div v-else>
        <table
          class="supplierTable"
          v-for="(item, index) in formData.filterData"
          :key="index"
          style="margin-bottom: 24px; width: 100%"
        >
          <tr class="h-[54px]" style="border-bottom: 1px solid #e9e9e9; background: #e5e5e5">
            <th style="text-align: center">{{ item.supplier.shortName }}</th>
            <th style="text-align: center">
              <span>{{ item.inqueryGoodsList[0].goods.preparedBy.name }}</span>
              <a-tooltip :title="item.inqueryGoodsList[0].goods.preparedBy.cellphone">
                <PhoneFilled class="ml-8px cursor-pointer" style="color: #0d8d8d; transform: rotateY(180deg)" />
              </a-tooltip>
              <MessageFilled
                v-if="item.inqueryGoodsList[0].goods.preparedBy.chatUser"
                @click="webim.open(item.inqueryGoodsList[0].goods.preparedBy.chatUser)"
                class="cursor-pointer operation-model"
                style="color: #ffd100; margin-left: 8px"
              />
            </th>
            <th colspan="4" class="font-bold text-[16px]" style="text-align: right; padding-right: 42px">
              {{ formatMoney(item.totalAmount, '￥', 2) }}
            </th>
          </tr>
          <tr v-for="row of item.inqueryGoodsList" :key="row.id" style="height: 54px">
            <td class="w-160px">
              <div class="w-160px truncate text-center">{{ row.name }}</div>
            </td>
            <td style="text-align: center; width: 120px; word-break: break-word">
              <span v-if="row.goods.oem.length < 12">{{ row.goods.oem }}</span>
              <a-tooltip :title="row.goods.oem" v-else>
                <div class="w-[100px] truncate">{{ row.goods.oem }}</div>
              </a-tooltip>
            </td>
            <td style="width: 100px">
              <span class="font-bold text-[16px]">￥{{ row.price }}</span
              >{{ ` x ${row.count}` }}
            </td>
            <td style="width: 100px">{{ row.goods.qualityType.message }}</td>
            <td style="width: 100px">{{ row.goods.inventoryExplain.message }}</td>
            <td style="text-align: right; padding-right: 42px">
              小计:
              <span class="font-bold text-[16px]"> {{ formatMoney(row.amount, '￥', 2) }} </span>
            </td>
          </tr>
        </table>
        <div class="flex items-center justify-end mt-[24px]">
          <span>选购 {{ summay.supplierCount }} 家供应商,</span>
          <span>&nbsp;共 {{ summay.size }} 种配件</span>
          <span style="margin-left: 24px">
            已选总额：
            <span class="font-bold text-[16px]">{{ formatMoney(summay.totalAmount, '￥', 2) }}</span>
          </span>
          <a-button style="margin-left: 24px" type="primary" @click="backClick">返回</a-button>
          <a-button style="margin-left: 24px" type="primary" @click="confirmClick">确定</a-button>
        </div>
      </div>
    </div>
    <a-drawer v-model:visible="drawer.visible" title="选择报价" width="570px">
      <div class="flex mb-[24px]">
        <div class="mr-[38px]">
          <div>配件名称</div>
          <div v-if="drawer.inquiryGoods.name.length < 12" class="text-[22px] pt-[12px]">
            {{ drawer.inquiryGoods.name }}
          </div>
          <a-tooltip v-else>
            <div class="w-[200px] truncate">{{ drawer.inquiryGoods.name }}</div>
          </a-tooltip>
        </div>
        <div>
          <div>数量</div>
          <div class="text-[22px] pt-[12px]">{{ drawer.inquiryGoods.count }}</div>
        </div>
      </div>
      <table
        v-for="(item, index) of drawer.data"
        :key="index"
        cellpadding="0"
        cellspacing="0"
        class="client-purcahse-quotation-replace__table"
      >
        <tr v-if="!index" style="background: #fafafa; height: 54px">
          <th class="w-40px"></th>
          <th class="w-80px">单价</th>
          <th class="w-90px">配件编码</th>
          <th class="w-80px">品质类型</th>
          <th class="w-60px">质保期</th>
          <th class="w-80px">订货周期</th>
          <th class="w-90px">供应商</th>
        </tr>

        <tr v-for="quotationgoods of item" :key="quotationgoods.id" style="height: 54px">
          <td class="w-40px text-center">
            <a-radio
              :checked="drawer.selection.id === quotationgoods.id"
              @click="drawerTableCheckedClick(quotationgoods)"
            ></a-radio>
          </td>
          <td>
            <a-tooltip :title="quotationgoods.price">
              <div class="w-[60px] truncate">{{ formatMoney(quotationgoods.price, '￥') }}</div>
            </a-tooltip>
          </td>
          <td class="w-[90px]">
            <span v-if="quotationgoods.oem.length < 6"> {{ quotationgoods.oem }}</span>
            <a-tooltip v-else :title="quotationgoods.oem">
              <div class="w-[80px] truncate">{{ quotationgoods.oem }}</div>
            </a-tooltip>
          </td>
          <td class="w-[80px]">
            <div class="flex items-center">
              <img
                v-if="quotationgoods.qualityType.code === 'O'"
                class="w-14px h-14px mr-8px"
                src="@client/assets/icon_origin.png"
                alt=""
              />
              <img
                v-if="quotationgoods.qualityType.code === 'B'"
                class="w-14px h-14px mr-8px"
                src="@client/assets/icon_quality.png"
                alt=""
              />
              <img
                v-if="quotationgoods.qualityType.code === 'D'"
                class="w-14px h-14px mr-8px"
                src="@client/assets/icon_car.png"
                alt=""
              />
              <span>{{ quotationgoods.qualityType.message }}</span>
            </div>
          </td>
          <td class="w-[60px]">{{ quotationgoods.warrantyMonth ? `${quotationgoods.warrantyMonth}个月'` : '无' }}</td>
          <td class="w-[80px]">
            {{ quotationgoods.reserveDays ? quotationgoods.reserveDays + '天' : '现货' }}
          </td>
          <td class="w-[90px]">{{ quotationgoods.supplier?.shortName }}</td>
        </tr>
      </table>

      <div class="drawer-footer">
        <a-button class="w-88px" style="margin-right: 8px" @click="drawer.visible = false">取消</a-button>
        <a-button class="w-88px" type="primary" @click="drawerOperationSubmitClick" :loading="confirmLoading"
          >确定</a-button
        >
      </div>
    </a-drawer>
    <a-drawer v-model:visible="supplierDrawer.visible" title="供应商" width="480px">
      <div>
        <ul>
          <li
            class="flex justify-between px-[10px] py-[16px] border"
            style="border-top: none; border-left: none; border-right: none"
            v-for="supplierItem in form.data.suppliers"
            :key="supplierItem.id"
          >
            <span>
              <span style="margin-right: 8px">{{ supplierItem.supplier.shortName }}</span>
              <a-tooltip v-if="supplierItem.chatUser" :title="supplierItem.chatUser.cellphone || '暂无'">
                <PhoneFilled class="cursor-pointer" style="color: #0d8d8d; transform: rotateY(180deg)" />
              </a-tooltip>
              <MessageFilled
                v-if="supplierItem.chatUser"
                @click="webim.open(supplierItem.chatUser)"
                class="cursor-pointer"
                style="color: #ffd100; margin-left: 8px"
              />
            </span>

            <span> {{ formatArea(supplierItem.supplier.area) }} </span>
          </li>
        </ul>
      </div>
      <div class="drawer-footer">
        <a-button class="w-88px" style="margin-right: 8px" @click="supplierDrawer.visible = false">取消</a-button>
      </div>
    </a-drawer>
    <a-modal v-model:visible="imgPreviewModal.visible" :footer="null" @cancel="imgPreviewModal.visible = false">
      <img alt="image" style="width: 100%" :src="form.data.inquiryImages[0]" />
    </a-modal>
    <a-modal
      v-model:visible="messageModal.visible"
      title="下单成功"
      :closable="false"
      :footer="null"
      :maskClosable="false"
    >
      <div class="flex justify-center items-center">
        <div>
          <div class="w-[64px] h-[64px] mx-auto">
            <img src="@client/assets/success.png" class="w-full" />
          </div>
          <div class="font-bold text-[20px] mb-[16px] mt-[32px] text-center">采购订单已生成</div>
          <ul class="mb-22px">
            <li
              class="h-28px flex items-center justify-center"
              style="color: rgba(0, 0, 0, 0.45)"
              v-for="(item, index) of messageModal.orderData"
              :key="index"
            >
              <span class="mr-20px">{{ item.supplier.shortName }}</span>
              <span>订单号 {{ item.serialNo }}</span>
            </li>
          </ul>

          <div style="text-align: center">
            <a-button class="mr-[8px] text-[#fff]" @click="goClient" type="primary">返回首页</a-button>
            <a-button @click="goOrder">查看订单</a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </view-wrapper>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue'
import { multiply, add } from 'lodash-es'
import { useAjax, formatDate, formatMoney, formatArea, useWebim } from '@vue-mfe/utils'
import { useRoute, useRouter } from 'vue-router'
import {
  message,
  Row as ARow,
  Col as ACol,
  Tag as ATag,
  Select as ASelect,
  SelectOption as ASelectOption,
  Checkbox as ACheckbox,
  Drawer as ADrawer,
  Radio as ARadio,
  Modal as AModal,
  Tooltip as ATooltip
} from 'ant-design-vue'
import { FileImageOutlined, PhoneFilled, MessageFilled } from '@ant-design/icons-vue'
import type { ColumnProps } from 'ant-design-vue/es/table/interface'

const route = useRoute()
const router = useRouter()
const webim = useWebim()
const id = route.query.id
interface QuotationGoodsState {
  id?: number
  oem: string
  qualityType: {
    code: string
    message: string
  }
  price: number
  supplier?: {
    shortName: string
  }
  warrantyMonth?: string
  reserveDays?: string | number
}

interface InquiryGoodsState {
  id?: number
  name: string
  count: number | string
  quotationGoods: QuotationGoodsState[]
  quotationCount: number
  goods?: QuotationGoodsState
  supplier?: string
  inqueryGoodsList?: any[]
  enocloudGoods?: {
    serialNo: string
  }
}
interface FormState {
  id?: number
  data: any
}

interface DrawerState {
  visible: boolean
  inquiryGoods: any
  data: QuotationGoodsState[][]
  selection: any
}
const tableColumns: ColumnProps[] = [
  {
    width: 60,
    align: 'center',
    slots: { customRender: 'checkbox', title: 'checkboxAll' },
    fixed: 'left',
    className: 'table-col-bgk'
  },
  { title: '序号', align: 'center', fixed: 'left', className: 'table-col-bgk', customRender: ({ index }) => index + 1 },
  { title: '配件名称', dataIndex: 'name', ellipsis: true, className: 'table-col-bgk', fixed: 'left' },
  { title: '数量', dataIndex: 'count', slots: { customRender: 'count' }, className: 'table-col-bgk', fixed: 'left' },
  { title: '配件编码', dataIndex: 'goods.oem', ellipsis: true },
  { title: '单价', dataIndex: 'goods.price', slots: { customRender: 'price' } },
  { title: '品质类型', dataIndex: 'goods.qualityType.message', slots: { customRender: 'qualityType' } },
  { title: '质保期', dataIndex: 'goods.warrantyMonth', slots: { customRender: 'warrantyMonth' } },
  { title: '订货周期', dataIndex: 'goods.reserveDays', slots: { customRender: 'reserveDays' } },
  { title: '供应商', dataIndex: 'goods.supplier.shortName' },
  { title: '报价人', dataIndex: 'goods.preparedBy.name', slots: { customRender: 'offerer' } },
  { title: '报价份数', dataIndex: 'quotationCount' },
  { title: '报价备注', dataIndex: 'goods.comment' },
  { title: '操作', align: 'center', slots: { customRender: 'operation' } }
]
const tableData = ref<InquiryGoodsState[]>([])
const filterRule = ref('')
const cartVisible = ref<boolean>(false)
let form = reactive<FormState>(
  {
    data: {
      serialNo: '',
      preparedBy: '',
      inquiryQualityTypes: [
        {
          code: 0,
          message: ''
        }
      ],
      preparedDatetime: '',
      invoiceType: {
        message: ''
      },
      suppliers: [],
      vin: '',
      comment: '',
      vehicleBrand: '',
      vehicleSubBrand: '',
      inquiryImages: [],
      inquiryGoods: [
        {
          quotationGoods: []
        }
      ],
      purchaseCount: 0,
      purchasedCount: 0
    }
  }
)
const formData = reactive(
  {
    price: 0 as number,
    supplierName: [] as any[],
    selectedData: [] as any[],
    filterData: [] as any[],
    flag: '' as any,
    suppliers: [] as any[]
  }
)
const qualityTypeCodes = computed(() => form.data.inquiryQualityTypes.map((item: any) => item.code))
const createPurchaseData = reactive(
  {
    id,
    purchases: [] as any[]
  }
)
const drawer = reactive<DrawerState>(
  {
    visible: false,
    inquiryGoods: {
      name: '',
      count: ''
    },
    data: [],
    selection: {
      qualityType: {
        code: '',
        message: ''
      }
    }
  }
)

const supplierDrawer = reactive(
  {
    visible: false
  }
)
const imgPreviewModal = reactive(
  {
    visible: false,
    openClick: () => {
      if (!form.data.inquiryImages[0]) return
      imgPreviewModal.visible = true
    }
  }
)
const messageModal = reactive(
  {
    visible: false,
    orderData: [] as any[]
  }
)
const reloadClick = () => {
  location.reload()
}
const reInquiryClick = () => {
  localStorage.setItem('INQUIRY_DATA', JSON.stringify(form.data))
  router.push({ path: `/client/purchase/inquiry/add`, query: { reInquiry: 'Y' } })
}
const goClient = () => router.push('/client')
const goOrder = () => router.push('/client/purchase/order')

const { run: tableGet, loading: formLoading } = useAjax(
  {
    action: 'GET /client/inquiry/:inquiryId',
    params: () => id,
    convert: {
      client (data) {
        formData.price = 0
        formData.supplierName = []
        return data.map(
          (item) => {
            item.inquiryGoods = item.inquiryGoods.map(
              (inner: any) => {
                inner.quotationCount = inner.quotationGoods.length
                return inner
              }
            )
            return item
          }
        )
      }
    },
    success (data) {
      formData.selectedData = []
      form.data = data[0]
      form.data.inquiryQualityTypes = data[0].inquiryQualityTypes.sort(
        (star: any, next: any) => {
          const codeOrder = ['O', 'B', 'D']
          return codeOrder.indexOf(star.code) - codeOrder.indexOf(next.code)
        }
      )
      if (!filterRule.value) {
        filterRule.value = form.data.inquiryQualityTypes[0].code
      }
      tableData.value = data[0].inquiryGoods.map(
        (item: any) => {
          const emptyGoods = { qualityType: { code: '', message: '' } }
          let filterGoods = item.quotationGoods
            .filter((item: any) => item.qualityType.code === filterRule.value)
            .sort((cur: any, pre: any) => cur.price - pre.price)
          const otherGoods = item.quotationGoods
            .filter((item: any) => item.qualityType.code !== filterRule.value)
            .sort(
              (cur: any, pre: any) => {
                const codeOrder = ['O', 'B', 'D']
                if (cur.qualityType.code === pre.qualityType.code) return cur.price - pre.price
                else codeOrder.indexOf(cur.qualityType.code) - codeOrder.indexOf(pre.qualityTypecode)
              }
            )
          item.goods = item.quotationGoods.length ? (filterGoods.length ? filterGoods[0] : otherGoods[0]) : emptyGoods
          if (item.quotationCount && item.goods.qualityType.code) {
            item.checked = true
            formData.selectedData.push(item)
          } else {
            item.checked = false
          }
          return item
        }
      )
    }
  }
)

const checkAll = computed(
  () => {
    const arr = tableData.value.filter((item: any) => item.quotationCount && item.goods.qualityType.code)
    if (arr.length) return arr.every((item: any) => item.checked)
    else false
  }
)
const checkAllChange = () => {
  formData.selectedData = []
  if (!checkAll.value) {
    tableData.value.forEach(
      (item: any) => {
        if (item.quotationCount && item.goods.qualityType.code) {
          formData.selectedData.push(item)
          item.checked = true
        }
      }
    )
  } else {
    tableData.value.forEach(
      (item: any) => {
        item.checked = false
      }
    )
  }
}
const checkChange = (row: any) => {
  if (row.checked && row.quotationCount && row.goods.qualityType.code) {
    formData.selectedData.push(row)
  } else {
    const index = formData.selectedData.findIndex((item: any) => item.id === row.id)
    if (index || index === 0) formData.selectedData.splice(index, 1)
  }
}

const purchaseClick = () => {
  if (formData.selectedData.length === 0) {
    return message.warning('请先选择配件')
  }
  formData.selectedData.forEach(
    (item: any) => {
      if (formData.filterData.length === 0) {
        formData.filterData.push(
          {
            supplier: item.goods.supplier,
            inqueryGoodsList: [{ ...item, price: item.goods.price }]
          }
        )
      } else {
        let filterDataItem = formData.filterData.find((inner: any) => inner.supplier.id === item.goods.supplier.id)
        if (!filterDataItem) {
          formData.filterData.push(
            {
              supplier: item.goods.supplier,
              inqueryGoodsList: [{ ...item, price: item.goods.price }]
            }
          )
        } else {
          filterDataItem.inqueryGoodsList.push({ ...item, price: item.goods.price })
        }
      }
    }
  )
  formData.filterData.forEach(
    (item: any) => {
      item.inqueryGoodsList.forEach(
        (inner: any) => {
          inner.amount = multiply(inner.count, inner.goods.price)
        }
      )
      item.totalAmount = item.inqueryGoodsList.reduce((amount: number, cur: any) => add(amount, cur.amount), 0)
    }
  )
  cartVisible.value = true
}
const tableOperationReplaceClick = (record: InquiryGoodsState) => {
  drawer.data = ['O', 'B', 'D'].map(
    (type) =>
      record.quotationGoods
        .filter((item) => item.qualityType.code === type)
        .sort((pre: any, next: any) => pre.price - next.price)
  )
  drawer.inquiryGoods = record
  drawer.selection = record.goods
  drawer.visible = true
}
const confirmClick = () => {
  formData.filterData.forEach(
    (item: any) => {
      createPurchaseData.purchases.push(
        {
          supplier: item.supplier,
          purchaseGoods: item.inqueryGoodsList.map(
            (inner: any) => ({
              count: inner.count,
              price: inner.goods.price,
              quotationGoods: { ...inner.goods }
            })
          )
        }
      )
    }
  )
  createInquery()
}
const backClick = () => {
  cartVisible.value = false
  formData.filterData = []
  formData.selectedData = []
  tableData.value.forEach(
    (item: any) => {
      item.checked = false
    }
  )
}
const { run: createInquery, loading: confirmLoading } = useAjax(
  {
    action: 'POST /client/inquiry/purchase',
    params: () => createPurchaseData,
    success (data) {
      messageModal.orderData = data
      messageModal.visible = true
    },
    lazy: true
  }
)
const drawerTableCheckedClick = (row: QuotationGoodsState) => {
  drawer.selection = row
}

const drawerOperationSubmitClick = () => {
  let current = tableData.value.find((item) => item.id === drawer.inquiryGoods.id)
  if (current) current.goods = drawer.selection
  drawer.visible = false
}
const toPercent = (value: number, precision = 2) => {
  return `${multiply(value, 100).toFixed(precision)}%`
}
const biddingData = computed(
  () => form.data.inquiryGoods.reduce((count: any, cur: any) => count + cur.quotationGoods.length, 0)
)
const finishingRate = computed(
  () =>
    toPercent(
      form.data.inquiryGoods.filter((item: any) => item.quotationGoods.length).length / form.data.inquiryGoods.length
    )
)

const summay = computed(
  () => {
    let totalAmount = 0
    const supplierId = [] as any[]
    formData.selectedData.forEach(
      (item: any) => {
        totalAmount += item.goods.price * item.count
        if (!supplierId.includes(item.goods.supplier.id)) {
          supplierId.push(item.goods.supplier.id)
        }
      }
    )
    return {
      totalAmount,
      size: formData.selectedData.length,
      supplierCount: supplierId.length
    }
  }
)
</script>
<style scoped>
.ant-form-item {
  margin-bottom: 0px;
}
.client-purcahse-quotation-replace__table {
  border: 1px solid #e9e9e9;
  width: 100%;
}

.client-purcahse-quotation-replace__table + .client-purcahse-quotation-replace__table {
  margin: 10px 0;
}
.supplierTable {
  border: 1px solid #e9e9e9;
  /* border-left: 1px solid #E9E9E9; */
}

.supplierTable td {
  border-bottom: 1px solid #e9e9e9;
}
.switch-block__input {
  display: none;
}
.switch-block:hover .switch-block__text {
  display: none;
}
.switch-block:hover .switch-block__input {
  display: inline-flex;
}
.block_table-col-bgk :deep(.ant-table-wrapper .ant-table-content th.table-col-bgk),
.block_table-col-bgk :deep(.ant-table-wrapper .ant-table-content td.table-col-bgk) {
  background-color: #d0f3f3 !important;
}
</style>
